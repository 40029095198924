/**
 * @see https://github.com/gcanti/io-ts/blob/master/index.md#the-idea
 * */

import * as t from 'io-ts';

const emptyArray = new t.Type<[], [], unknown>(
    'emptyArray',
    (input: unknown): input is [] => Array.isArray(input) && input.length === 0,
    (input, context) => (Array.isArray(input) && input.length === 0
        ? t.success(input as [])
        : t.failure(input, context)),
    t.identity,
);

export default emptyArray;
