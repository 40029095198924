<template>
    <div
        :id="id"
        ref="tooltip"
        class="tooltip-wrapper"
        @mouseover="showTooltip"
        @mouseout="hideTooltip"
    >
        <slot />
        <Teleport to="body">
            <div
                v-if="tooltipVisible"
                ref="elementTooltip"
                :class="customClass"
                :style="styles"
                role="tooltip"
            >
                <div class="tooltip__inner">
                    <span class="tooltip__title">
                        {{ title }}
                    </span>
                    <div
                        v-if="!!content"
                        class="tooltip__content"
                    >
                        {{ content }}
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script lang="ts" setup>
import {
    ref, computed, watch, nextTick,
} from 'vue';
import { CommentOverlayFontSize, Position } from '../../store/modules/globalSettings';
import DropdownVariant from './DropdownVariant';
import { useWindow } from '../../composables/window';

type Props = {
    id?: string,
    title?: string | null,
    content?: string,
    placement?: string,
    boundary?: HTMLElement | string,
    noOpacity?: boolean,
    variant?: DropdownVariant,
    boundaryPadding?: number,
    size?: CommentOverlayFontSize,
    noMaxWidth?: boolean,
    noMinWidth?: boolean,
    offset?: number,
    disabled?: boolean,
    whiteSpace?: string
};

const props = withDefaults(
    defineProps<Props>(),
    {
        id: '',
        title: '',
        content: '',
        placement: Position.BOTTOM,
        boundary: '',
        noOpacity: false,
        variant: DropdownVariant.MATE_GRAY,
        boundaryPadding: 50,
        size: CommentOverlayFontSize.SMALL,
        noMaxWidth: false,
        noMinWidth: false,
        offset: 20,
        disabled: false,
        whiteSpace: 'nowrap',
    },
);

const tooltipVisible = ref<boolean>(false);
const window = useWindow();

const sizeClass = computed<string>(() => {
    switch (props.size) {
    case CommentOverlayFontSize.BIG:
        return 'tooltip--big';
    case CommentOverlayFontSize.SMALL:
        return 'tooltip--sm';
    case CommentOverlayFontSize.MEDIUM:
        return 'tooltip--md';
    default:
        return '';
    }
});

const placementClass = computed<string>(() => {
    switch (props.placement) {
    case Position.BOTTOM:
        return 'tooltip--bottom';
    case Position.TOP:
        return 'tooltip--top';
    case Position.LEFT:
        return 'tooltip--left';
    case Position.RIGHT:
        return 'tooltip--right';
    default:
        return '';
    }
});

const customClass = computed<string>(() => {
    const classes = [
        'tooltip',
        sizeClass.value,
        placementClass.value,
        `tooltip--${props.variant}`,
    ];

    if (props.noOpacity) {
        classes.push('tooltip--no-opacity');
    }

    if (props.noMaxWidth) {
        classes.push('tooltip--no-max-width');
    }

    if (props.noMinWidth) {
        classes.push('tooltip--no-min-width');
    }
    return classes.join(' ')
        .trim();
});

const tooltip = ref<HTMLDivElement | null>(null);

const elementTooltip = ref<HTMLDivElement | null>(null);

const showTooltip = () => {
    if (!props.disabled) {
        tooltipVisible.value = true;
    }
};

const hideTooltip = () => {
    tooltipVisible.value = false;
};

const getTarget = (): HTMLElement|null => {
    if (props.id) {
        return document.getElementById(props.id);
    }
    return tooltip.value?.children[0] as HTMLElement;
};

const getTopOffset = () => {
    if (tooltip.value === null) {
        return null;
    }

    const target = getTarget();

    if (target === null) {
        return null;
    }

    if (elementTooltip.value === null) {
        return null;
    }

    const { height: tooltipHeight } = elementTooltip.value.getBoundingClientRect();
    const {
        top: targetTop,
        height: targetHeight,
    } = target.getBoundingClientRect();

    if (props.placement === Position.LEFT) {
        return targetTop;
    }

    if (props.placement === Position.RIGHT) {
        return (targetTop
            + (targetHeight / 2)
            - (tooltipHeight / 3));
    }

    if (props.placement === Position.TOP) {
        return targetTop
            - targetHeight
            - props.offset;
    }

    if (props.placement === Position.BOTTOM) {
        return targetTop + targetHeight;
    }

    return null;
};

const getLeftOffset = () => {
    if (tooltip.value === null) {
        return null;
    }

    const target = getTarget();

    if (target === null) {
        return null;
    }

    if (elementTooltip.value === null) {
        return null;
    }

    const { width: tooltipWidth } = elementTooltip.value.getBoundingClientRect();
    const {
        left: targetLeft,
        width: targetWidth,
    } = target.getBoundingClientRect();

    if (props.placement === Position.RIGHT) {
        return targetWidth;
    }

    if (props.placement === Position.LEFT) {
        return targetLeft - targetWidth / 4 - tooltipWidth;
    }

    if (props.placement === Position.TOP) {
        let position = (targetLeft + (targetWidth / 2))
            - (tooltipWidth / 2);

        if ((position + tooltipWidth) > window.innerWidth.value) {
            position -= tooltipWidth / 2;
        }

        return Math.max(0, position);
    }

    if (props.placement === Position.BOTTOM || props.placement === Position.BOTTOM_LEFT) {
        let position = (targetLeft + (targetWidth / 2))
            - (tooltipWidth / 2);

        if ((position + tooltipWidth) > window.innerWidth.value) {
            position = window.innerWidth.value - tooltipWidth;
        }

        return Math.max(0, position);
    }

    return null;
};

const leftOffset = ref<number|null>(0);
const topOffset = ref<number|null>(0);

type Styles = {
    top: string,
    left: string,
};

watch(tooltipVisible, async () => {
    await nextTick();
    if (tooltipVisible.value) {
        leftOffset.value = getLeftOffset();
        topOffset.value = getTopOffset();
    }
});

const styles = computed<Styles>(() => ({
    top: topOffset.value ? `${topOffset.value}px` : '',
    left: leftOffset.value ? `${leftOffset.value}px` : '',
}));

watch(() => props.disabled, () => {
    if (props.disabled && tooltipVisible.value) {
        tooltipVisible.value = false;
    }
});
</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/font-sizes';

.tooltip {
    position: fixed;
    z-index: $tooltip-z-index;

    display: block;

    margin: 0;

    font-size: $font-size-m;

    text-align: start;

    text-decoration: none;

    text-shadow: none;

    text-transform: none;

    letter-spacing: normal;

    word-break: normal;

    word-wrap: break-word;

    white-space: v-bind(whiteSpace);

    word-spacing: normal;

    line-break: auto;

    &__inner {
        min-width: 3.75rem;
        max-width: 30rem;
        padding: $spacing-xxsm $spacing-xs;

        color: var(--theme-color-text-inversed);

        text-align: center;

        border-radius: $border-radius;
    }

    &__title:has(+ &__content) {
        font-weight: $font-weight-bold;
    }

    &__content {
        white-space: break-spaces;
    }

    &.show {
        opacity: 0.9;
    }

    &--no-opacity {
        opacity: 1;
    }

    &--no-min-width &__inner {
        min-width: unset;
    }

    &--no-max-width &__inner {
        max-width: unset;
    }

    &--mate-gray {
        color: var(--theme-color-text-light);

        .tooltip__inner {
            background-color: var(--theme-color-surface-inversed-primary);
            backdrop-filter: blur(4px);
        }
    }

    &--big {
        font-size: $font-size-xl;
    }

    &--sm {
        font-size: $font-size-sm;
    }

    &--md {
        font-size: $font-size-lg;
    }

    &--top,
    &--bottom {
        padding: $spacing-xxsm 0;
    }

    &--right,
    &--left {
        padding: 0 $spacing-xxsm;
    }
}
</style>
