<template>
    <Transition
        :duration="SIDEBAR_DURATION"
        name="sidebar"
        appear
        @after-enter="onAfterEnter"
        @after-leave="onAnimationEnd"
    >
        <aside
            v-if="isShown"
            v-bind="$attrs"
            :id="id"
            class="sidebar"
            data-cy="sidebar"
            aria-label="sidebar"
        >
            <slot name="header">
                <PrimaryHeader
                    v-if="isLgOrMore"
                    :title="title"
                    class="sidebar__header"
                >
                    <template #right-slot-desktop>
                        <slot
                            v-if="hasRightSlotDesktop"
                            name="right-slot-desktop"
                        />
                        <Button
                            v-else
                            has-only-icon
                            variant="light"
                            size="xxs"
                            aria-label="Close sidebar"
                            @click="hide"
                        >
                            <CloseIcon class="close-button__icon" />
                        </Button>
                    </template>
                </PrimaryHeader>
            </slot>

            <div
                class="sidebar__content"
                :class="contentClass"
            >
                <slot />
            </div>

            <slot
                name="footer"
                :class-name="$style['sidebar-footer']"
            />
        </aside>
    </Transition>
</template>

<script lang="ts" setup>
import {
    computed, onUnmounted, useSlots,
} from 'vue';
import ButtonKeyListener from '../../../scripts/helpers/listeners/ButtonKeyListener';
import type { SidebarProps } from './SidebarProps';
import { mediaQueriesModule } from '../../store';
import PrimaryHeader from '../Header/PrimaryHeader.vue';
import CloseIcon from '../../../images/x.svg?component';
import Button from '../Elements/Button.vue';

const SIDEBAR_DURATION = 350;

type Props = {
    id: SidebarProps['id'],
    title: SidebarProps['title'],
    isShown: SidebarProps['isShown'],
    contentClass?: SidebarProps['contentClass'],
};

type Emits = {
    (event: 'hide'): void,
    (event: 'afterEnter'): void,
    (event: 'hidden'): void,
};

withDefaults(defineProps<Props>(), {
    title: '',
    isShown: false,
    contentClass: null,
});

const slots = useSlots();

const emit = defineEmits<Emits>();

const isLgOrMore = computed(() => mediaQueriesModule.isLgOrMore);

const hide = () => {
    emit('hide');
};

const hasRightSlotDesktop = computed<boolean>(() => !!slots['right-slot-desktop']);

const onAfterEnter = () => {
    emit('afterEnter');
};

const buttonKeyListener = new ButtonKeyListener(
    (event: KeyboardEvent) => event.key === 'Escape',
    hide,
);

buttonKeyListener.registerListener();

onUnmounted(() => {
    buttonKeyListener.unregisterListener();
});

const onAnimationEnd = () => {
    emit('hidden');
};

</script>

<style lang="scss" scoped>
@import '../../../styles/abstracts/colors_old';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/functions';

.sidebar {
    display: flex;

    flex-direction: column;
    flex-shrink: 0;

    width: 21rem;
    max-width: 42rem;
    height: 100vh;
    overflow: hidden;

    background-color: var(--theme-color-surface-primary-default);

    transition: width 0.35s ease;

    &__content {
        flex-grow: 1;

        overflow: hidden;
    }
}

.sidebar-enter-active,
.sidebar-leave-active {
    white-space: nowrap;

}

.sidebar-enter-from,
.sidebar-leave-to {
    width: 0;
}
</style>

<style lang="scss" module>
@import '../../../styles/abstracts/spacings';

.sidebar-footer {
    padding: $spacing-xl;
}
</style>
