import * as t from 'io-ts';
import { TypeOf } from 'io-ts';
import { Choice, ChoiceC } from '../types/Choice';
import { FieldCommonPropsC } from '../../types/FieldCommonProps';
import FIELD_RENDER_TYPES from '../../../fieldRenderTypes';
import emptyArray from '../../../../io-ts/emptyArray';

const ItemsC = t.intersection([
    t.type({
        enum: t.array(t.string),
        enum_titles: t.array(t.string),
        enum_selected: t.union([t.array(t.string), emptyArray]),
        enum_disabled: t.union([t.array(t.string), emptyArray]),
    }),
    t.partial({
        maxItems: t.Int,
        minItems: t.Int,
        enum_colors: t.union([t.array(t.string), emptyArray]),
        enum_tooltips: t.union([t.array(t.string), emptyArray]),
    }),
]);

export const RawMultipleChoiceWithoutRendererC = t.type({
    title: t.union([t.string, t.null]),
    name: t.string,
    type: t.literal('array'),
    items: ItemsC,
});

export const RawMultipleChoiceC = t.intersection([
    RawMultipleChoiceWithoutRendererC,
    t.type({
        renderer: t.literal(FIELD_RENDER_TYPES.MULTIPLE_CHOICE),
    }),
]);

export const MultipleChoicePartialWithoutRendererC = t.type({
    choices: t.array(ChoiceC),
});

export const MultipleChoicePartialC = t.intersection([
    MultipleChoicePartialWithoutRendererC,
    t.type({
        renderAs: t.literal(FIELD_RENDER_TYPES.MULTIPLE_CHOICE),
    }),
]);

export const MultipleChoiceC = t.intersection([MultipleChoicePartialC, FieldCommonPropsC]);

export type RawMultipleChoiceWithoutRenderer = TypeOf<typeof RawMultipleChoiceWithoutRendererC>;
export type RawMultipleChoice = TypeOf<typeof RawMultipleChoiceC>;
export type MultipleChoicePartial = TypeOf<typeof MultipleChoicePartialC>;
export type MultipleChoice = TypeOf<typeof MultipleChoiceC>;

const transformItemsToChoices = (
    items: TypeOf<typeof ItemsC>,
): Choice[] => items.enum.map(
    (value, i): Choice => {
        const title = items.enum_titles[i];
        const color = items.enum_colors?.[i];
        const tooltip = items.enum_tooltips?.[i];
        const selected = items.enum_selected.includes(value as never);
        const disabled = items.enum_disabled.includes(value as never);

        const defaults = {
            value, title, selected, disabled,
        };

        if (color) {
            return { ...defaults, color };
        }

        if (tooltip) {
            return { ...defaults, tooltip };
        }

        return defaults;
    },
);

export default (field: RawMultipleChoiceWithoutRenderer): MultipleChoicePartial => {
    const choices = transformItemsToChoices(field.items);

    return {
        renderAs: FIELD_RENDER_TYPES.MULTIPLE_CHOICE,
        choices,
    };
};
