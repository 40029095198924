<template>
    <FormSidebar
        :id="id"
        :key="id"
        v-model:form="content"
        :title="sidebar?.title"
        :action-button="sidebar?.button"
        :action="sidebar?.action"
        :pre-action="sidebar?.preAction"
        :validatable="sidebar?.validatable"
        :open-observe-on-close="sidebar?.openObserveOnClose"
        :update-action="sidebar?.update"
        :is-shown="show"
        @save="onSave($event.response)"
        @hide="hide"
        @hidden="onHidden"
    />
</template>

<script lang="ts" setup>
import {
    computed, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { sidebarModule } from '../../store';
import {
    EventType, Response,
    Event, ResponseEvent,
} from '../../store/modules/sidebar';
import { Form } from '../../../scripts/helpers/schemaConverter/converters/types/Form';
import FormSidebar from './FormSidebar.vue';

const router = useRouter();

const sidebar = computed(() => sidebarModule.sidebar);
const hasSidebar = computed(() => sidebarModule.hasSidebar);

const createTarget = () => ({
    $router: router,
});

const show = ref(hasSidebar.value);

const hide = (): void => {
    show.value = false;
};

const onHidden = () => {
    const event: Event = { type: EventType.CANCEL, target: createTarget() };
    sidebarModule.dispatch(event);
    sidebarModule.clearSidebar();
};

const onSave = (response: Response): void => {
    const event: ResponseEvent = { type: EventType.SAVE, target: createTarget(), response };
    sidebarModule.dispatch(event);
};

const content = computed<Form|undefined>({
    get: () => sidebar.value?.content,
    set: (value) => {
        if (value === undefined) {
            hide();
            return;
        }

        sidebarModule.setContent(value);
    },
});

const id = computed<string>(
    () => (content.value ? `sidebar_${content.value.name}` : 'sidebar_empty'),
);

watch(hasSidebar, () => {
    show.value = hasSidebar.value;
});

</script>
