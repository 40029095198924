<template>
    <a
        v-if="typeof to === 'string' && isAnchor"
        v-bind="$attrs"
        :href="to"
        target="_blank"
        class="link"
        :class="`link--${variant}`"
    >
        <slot />
    </a>
    <RouterLink
        v-else-if="to"
        v-slot="data"
        :to="to"
        custom
    >
        <Component
            :is="tag"
            v-if="data"
            v-bind="$attrs"
            :href="data.href"
            :class="data.isActive ? activeClass : null"
            @click="data.navigate"
        >
            <slot />
        </Component>
    </RouterLink>
    <Component
        :is="tag"
        v-else
        v-bind="$attrs"
        @click="$emit('click')"
    >
        <slot />
    </Component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

defineOptions({
    compatConfig: {
        MODE: 3,
    },
});

const VARIANT_PRIMARY = 'primary';
const VARIANT_SECONDARY = 'secondary';

type Variant = typeof VARIANT_PRIMARY | typeof VARIANT_SECONDARY;

type Props = {
    to?: string | RouteLocationRaw | null,
    tag?: string,
    activeClass?: string | null,
    variant?: Variant,
}

const props = withDefaults(defineProps<Props>(), {
    tag: 'a',
    to: null,
    activeClass: null,
    variant: 'primary',
});

const isAnchor = computed(() => {
    const isExternalLink = typeof props.to === 'string' && props.to.startsWith('http');
    const noLink = props.to === null;

    return noLink || isExternalLink;
});
</script>

<style lang="scss" scoped>
.link {
    cursor: pointer;

    &--primary {
        color: var(--theme-color-text-accent);
    }

    &--secondary {
        color: var(--theme-color-text-link);
    }
}
</style>
