<template>
    <header
        class="header"
        :class="classes"
    >
        <div
            ref="titleContainerElement"
            class="header__title header-title"
        >
            <div
                v-if="hasBeforeTitleContent"
                class="header-before-title-content"
            >
                <slot name="before-title-content" />
            </div>
            <div
                v-if="hasLeftSlot"
                class="header__slot header__slot--left"
                :class="leftSlotClass"
            >
                <slot name="left-slot" />
            </div>
            <Tooltip
                v-if="withTooltip && showTooltip"
                id="header-tooltip"
                :title="title"
            >
                <Component
                    :is="titleTag"
                    :id="headerId"
                    ref="titleElement"
                    :data-cy="cypressId"
                    class="header-title__text"
                >
                    {{ title }}
                </Component>
            </Tooltip>
            <Component
                :is="titleTag"
                v-else
                :id="headerId"
                ref="titleElement"
                :data-cy="cypressId"
                class="header-title__text"
            >
                {{ title }}
            </Component>
            <div class="header-after-title-content">
                <slot name="after-title-content" />
            </div>
        </div>

        <div
            v-if="isNavigationShown"
            class="header__navigation"
        >
            <slot name="navigation" />
        </div>

        <div class="header__slot header__slot--right">
            <slot name="right-slot" />
        </div>
    </header>
</template>

<script lang="ts" setup>
import {
    computed, onMounted, ref, useSlots,
} from 'vue';
import { mediaQueriesModule } from '../../store';
import Tooltip from '../Elements/Tooltip.vue';
import HeaderVariant from './HeaderVariant';

type Props = {
    title?: string,
    headerId?: string|null,
    cypressId?: string|null,
    level?: number,
    variant?: string,
    bordered?: boolean,
    leftAligned?: boolean,
    noHorizontalPadding?: boolean,
    leftSlotClass?: string| null
    withTooltip?: boolean,
};

const props = withDefaults(defineProps<Props>(), {
    title: 'Intelligent Video Solutions',
    level: 2,
    bordered: false,
    leftAligned: false,
    noHorizontalPadding: false,
    leftSlotClass: null,
    withTooltip: false,
    variant: HeaderVariant.DEFAULT,
    headerId: null,
    cypressId: null,
});

const titleElement = ref<HTMLElement | null>(null);
const titleContainerElement = ref<HTMLElement | null>(null);

const showTooltip = ref(false);

onMounted(() => {
    if (!props.withTooltip) {
        return;
    }
    if (titleElement.value && titleContainerElement.value) {
        showTooltip.value = titleElement.value.scrollWidth
                > titleContainerElement.value.scrollWidth;
    }
});

const titleTag = computed(() => `h${props.level}`);

const slots = useSlots();

const hasBeforeTitleContent = computed(() => !!slots['before-title-content']);
const hasLeftSlot = computed(() => !!slots['left-slot']);
const hasNavigation = computed(() => !!slots.navigation);

const isNavigationShown = computed(() => (
    hasNavigation.value && mediaQueriesModule.isLgOrMore
));

const classes = computed(() => ({
    'header--no-navigation': !isNavigationShown.value,
    'header--bordered': props.bordered,
    'header--left-aligned': props.leftAligned,
    'header--no-horizontal-padding': props.noHorizontalPadding,
    'header--default': props.variant === HeaderVariant.DEFAULT,
    'header--primary': props.variant === HeaderVariant.PRIMARY,
}));
</script>

<style lang="scss" scoped>
@import '../../../styles/vendors/breakpoints';
@import '../../../styles/abstracts/spacings';
@import '../../../styles/abstracts/font-sizes';
@import '../../../styles/abstracts/mixins';

.header-title {
    display: flex;

    flex-direction: row;

    align-items: center;

    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;

    &__text {
        @include text-ellipsis;

        margin: 0;
    }
}

.header {
    display: grid;

    flex-shrink: 0;

    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-gap: $spacing-s;

    align-items: stretch;
    justify-content: space-between;

    height: 4rem;
    padding: $spacing-s $spacing-xl;

    &--no-navigation {
        grid-template-columns: auto auto;
    }

    &--bordered {
        @include has-horizontal-rule($spacing-s);

        @include media-breakpoint-up(lg) {
            @include has-horizontal-rule();
        }
    }

    &--left-aligned {
        grid-template-columns: repeat(3, auto);

        justify-content: flex-start;
    }

    &--no-horizontal-padding {
        padding: $spacing-s 0;
    }

    &__slot {
        &--left {
            width: 1.75rem;
            margin-right: $spacing-s;

            @include media-breakpoint-up(lg) {
                width: 1.5rem;
                margin-right: $spacing-xs;
            }
        }

        &--right {
            display: flex;

            align-items: center;
            justify-content: flex-end;
        }
    }

    &__navigation {
        display: flex;

        align-items: center;

        justify-content: center;
    }

    &--primary {
        .header-title__text {
            font-size: $font-size-lg;
        }

        .header__slot--left {
            margin-right: 0;
        }
    }
}

.header-before-title-content {
    margin-right: $spacing-xs;
}

.header-after-title-content {
    margin-left: $spacing-xs;
}
</style>
