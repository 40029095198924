export const commentMessageHTMLReplacer = (message: string) => {
    let value = message.replace(/~\[(u|g):([^\]]*)\]/g, (match, type, name) => `<span data-type="${type}" class="comment-mention">@${name}</span>`);

    if (value.endsWith('</span>')) {
        value += '&nbsp;';
    }

    return value;
};

const commentMessageHTMLRestorer = (message: string) => {
    const value = message.replace(/<span data-type="(u|g)" class="comment-mention">([^@]*)@([^<]*)<\/span>/g, (_, type, prefix, name) => `${prefix}~[${type}:${name}]`);

    const endsWithReplacement = /~\[[ug]:[^~]*\]$/.test(value);

    if (endsWithReplacement) {
        return `${value} `;
    }

    return value;
};

export const toRawValue = (text: string) => commentMessageHTMLRestorer(text)
    .replaceAll('&nbsp;', ' ')
    .replaceAll('<br>', '\n');

export const toContentEditable = (text: string) => commentMessageHTMLReplacer(
    text.replaceAll(' ', '&nbsp;')
        .replaceAll('\n', '<br>'),
);
